import axios from "axios";
import { $Session } from "../helpers/storage";
import $config from "../helpers/config";

export default (type) =>
  axios.create({
    baseURL: $config.apiBaseURL,
    headers: {
      "Content-Type":
        type == "formdata" ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${
        $Session.hasItem("access-token") ? $Session.getItem("access-token") : ""
      }`,
    },
  });

export const multipart = axios.create({
  baseURL: $config.apiBaseURL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${
      $Session.hasItem("access-token") ? $Session.getItem("access-token") : ""
    }`,
  },
});
